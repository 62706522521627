import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import type { CheckoutRequest, CheckoutUI, DuplicateBooking, PaymentData } from "@/types/checkout";
import type { PricesData, Price, AttributePrice } from "@/types/prices";

import { useCustomerStore } from "@/stores/customerStore";
import { useProductSelectionsStore } from "@/stores/productSelectionsStore";
import { useProductStore } from "@/stores/productStore";

import { useStripePayment } from "@/composables/useStripePayment";

export const useCheckoutStore = defineStore("checkoutStore", () => {
  // State
  const formSubmitted = ref(false);
  const stripeToken = ref<string>("");
  // Loading states
  const checkoutLoading = ref(false);
  // Error states
  const checkoutError = ref<string | null>(null);
  // form data for backend
  const checkoutRequest = ref<CheckoutRequest>({} as CheckoutRequest);
  // form data for frontend
  const checkoutUI = ref<CheckoutUI>({
    successUrl: "",
    isPrepaid: false,
    paymentMethods: [],
    prices: {} as PricesData,
    amountToPay: 0,
    checkoutPage: "",
    bookingToken: "",
    isPaymentInProgress: false,
    termsAccepted: false,
    isSubmitDisabled: false,
    duplicateBooking: null,
    confirmationEmailSent: false,
  });
  const paymentData = ref<PaymentData>({} as PaymentData);

  const { productOptions } = storeToRefs(useProductSelectionsStore());
  const productStore = useProductStore();

  // Actions
  const handleSubmit = async () => {
    if (!validateForm()) return;

    const customerStore = useCustomerStore();
    if (!customerStore.validate()) return;

    checkoutUI.value.isSubmitDisabled = true;
    checkoutUI.value.isPaymentInProgress = true;

    checkoutRequest.value = {
      ...checkoutRequest.value,
      ...customerStore.customer,
      pax: productOptions.value.selectedPax,
      children_pax: productOptions.value.selectedChildrenPax,
      attributes: Object.keys(productOptions.value.selectedAttributes).map((id) => ({
        id,
        amount: productOptions.value.selectedAttributes[id]
      })),
      payment_conditions_id: productStore.productInfo?.payment_conditions?.id || null,
    };

    const minmaxResponse = await checkMinMaxPax();
    if (!minmaxResponse?.isValid) {
      console.log("PAD-2752: show minmax error");
      return;
    }

    // PAD-2751: Disabling duplicate check unless we need it.
    // const duplicateBookingResponse = await checkDuplicateBooking();
    // if (duplicateBookingResponse?.length > 0) {
    //   checkoutUI.value.duplicateBooking = duplicateBookingResponse[0];
    //   return;
    // }

    createBooking();
  };

  const createBooking = async () => {
    checkoutUI.value.duplicateBooking = null;
    checkoutUI.value.confirmationEmailSent = false;

    setupPaymentData();
    const bookingResponse = await createWebsiteBooking();
    if (!bookingResponse) return;

    console.log("PAD-2764: create affiliate tracking");
    console.log("PAD-2750: create ortto contact");

    checkoutUI.value.bookingToken = bookingResponse.token;

    if (checkoutUI.value.isPrepaid && bookingResponse.payment_required) {
      await initPaymentSession();
    } else {
      redirectToConfirmation();
    }
  }
  const validateForm = (): boolean => {
    formSubmitted.value = true;
    return checkoutUI.value.termsAccepted && !!checkoutRequest.value.payment_method;
  };

  const getPrice = (prices: Price[], pax = 0): number => {
    if (!prices || !prices.length) return 0;
    const price = prices.find(p => pax >= p.min_participants && pax <= p.max_participants);
    return price ? parseFloat(price.selling_price) : 0;
  };

  const getTotalLineItemPrice = (prices: Price[], pax = 0): number => {
    if (!prices || pax === 0) return 0;
    return pax * getPrice(prices, pax);
  };

  const getAttributePrice = (prices: AttributePrice[], attrCount = 0): number => {
    if (!prices || !prices.length) return 0;
    if (prices.length === 1) return prices[0].selling_price;
    const price = prices.find(p => attrCount >= p.amount && attrCount <= p.amount);
    return price ? price.selling_price : 0;
  };

  const getTotalLineItemAttributePrice = (prices: AttributePrice[], attrCount = 0): number => {
    if (!prices || attrCount === 0) return 0;
    return attrCount * getAttributePrice(prices, attrCount);
  };

  const calculateGrossTotal = (): number => {
    const adultTotal = getTotalLineItemPrice(checkoutUI.value.prices.prices, productOptions.value.selectedPax);
    const childrenTotal = getTotalLineItemPrice(checkoutUI.value.prices.children_prices, productOptions.value.selectedChildrenPax);
    const bookingFee = productStore.bookingFee;

    const attributesTotal = productOptions.value.attributes.reduce((acc, attribute) => {
      return acc + getTotalLineItemAttributePrice(attribute.prices, productOptions.value.selectedAttributes[attribute.id]);
    }, 0);

    return adultTotal + childrenTotal + attributesTotal + bookingFee;
  };

  const updateAmountToPay = () => {
    checkoutUI.value.amountToPay = calculateGrossTotal();
  };

  const checkMinMaxPax = async () => {
    checkoutLoading.value = true;
    checkoutError.value = null;

    try {
      const response = await fetch(`${import.meta.env.VITE_PUBLIC_ORDER_URL}min-pax-check`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(checkoutRequest.value),
      });

      if (!response.ok) throw new Error("Failed to check min max");
      return (await response.json()).data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      checkoutLoading.value = false;
    }
  };

  const checkDuplicateBooking = async () => {
    checkoutLoading.value = true;
    checkoutError.value = null;

    let url = `${import.meta.env.VITE_PUBLIC_ORDER_URL}?`;
    url += `email=${checkoutRequest.value.customer_email}`;
    url += `&city_id=${productStore.productInfo?.city_id}`;
    url += `&date=${checkoutRequest.value.tour_date}`;
    url += `&limit=true`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to check duplicate booking");
      return (await response.json()).data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      checkoutLoading.value = false;
    }
  };

  const createWebsiteBooking = async () => {
    checkoutLoading.value = true;
    checkoutError.value = null;

    const requestData = {
      ...checkoutRequest.value,
      payment_method: paymentData.value.payment_method,
    };

    try {
      const response = await fetch(`${import.meta.env.VITE_INTEGRATION_API_URL}/public/consumer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) throw new Error("Failed to create booking");
      return (await response.json()).data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      checkoutLoading.value = false;
    }
  };

  const setupPaymentData = () => {
    paymentData.value = {
      payment_method: checkoutRequest.value.payment_method,
      customer_email: checkoutRequest.value.customer_email,
      return_url: checkoutUI.value.checkoutPage,
    };

    switch (checkoutRequest.value.payment_method) {
      case "ideal":
      case "bancontact":
        paymentData.value.owner_name = `${checkoutRequest.value.first_name} ${checkoutRequest.value.last_name}`;
        if (checkoutRequest.value.payment_method === "bancontact") {
          paymentData.value.preferred_language = checkoutRequest.value.tour_language;
        }
        break;
      case "google_pay":
      case "apple_pay":
        paymentData.value.payment_method = "credit_card";
        break;
      case "delayed":
        paymentData.value.payment_method = "delayed";
        break;
      default:
        paymentData.value.payment_method = "credit_card";
        break;
    }
  };

  const initPaymentSession = async () => {
    const { initPaymentSession } = useStripePayment();

    stripeToken.value = await initPaymentSession(checkoutUI.value.bookingToken, paymentData.value);
  };

  const redirectToConfirmation = () => {
    window.location.href = `${checkoutUI.value.successUrl}?token=${checkoutUI.value.bookingToken}&tourLang=${checkoutRequest.value.tour_language}`;
  };

  const isOrderPaymentConfirmed = async (): Promise<boolean> => {
    const { checkPaymentConfirmation } = useStripePayment();
    const response = await checkPaymentConfirmation(checkoutUI.value.bookingToken);
    if (response.success) redirectToConfirmation();
    return response.success;
  };

  const resendConfirmationEmail = async (bajaId: string) => {
    checkoutUI.value.duplicateBooking = null;
    checkoutLoading.value = true;
    checkoutError.value = null;

    try {
      const response = await fetch(`${import.meta.env.VITE_PUBLIC_ORDER_URL}${bajaId}/remind`, {
        method: "GET",
      });

      if (!response.ok) throw new Error("Failed to resend confirmation email");

      const data = (await response.json()).data;
      if (data.isNotified) {
        checkoutUI.value.confirmationEmailSent = true;
      } else {
        throw new Error("Failed to resend confirmation email");
      }

      return data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      checkoutLoading.value = false;
    }
  };

  // Getters
  const isWallet = computed(() => {
    return ["google_pay", "apple_pay"].includes(checkoutRequest.value.payment_method);
  });

  const amountToPayInCents = computed(() => {
    return Math.round(checkoutUI.value.amountToPay * 100);
  });

  return {
    // State
    checkoutRequest,
    checkoutUI,
    checkoutLoading,
    formSubmitted,
    stripeToken,
    paymentData,

    // Actions
    handleSubmit,
    createBooking,
    validateForm,
    updateAmountToPay,
    isOrderPaymentConfirmed,
    resendConfirmationEmail,

    calculateGrossTotal,
    getTotalLineItemPrice,
    getPrice,
    getAttributePrice,
    getTotalLineItemAttributePrice,

    // Getters
    isWallet,
    amountToPayInCents,
  };
});