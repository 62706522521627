<template>
  <div class="col-lg">
    <div class="d-flex mb-3">
      <Email class="me-2" />
      <span v-if="!isSubmitSuccessful" class="fw-bold">
        {{ $t('booking_confirmation.share_booking') }}
      </span>
      <span v-else class="fw-bold">
        {{ $t('booking_confirmation.success_heading') }}
      </span>
    </div>

    <div v-if="!isSubmitSuccessful && !isSubmitFailed">
      <div
        class="row align-items-start position-relative"
        v-for="(friend, index) in friends"
        :key="index"
      >

        <div class="col-md mb-3">
          <input
            type="text"
            :placeholder="$t('booking_confirmation.email')"
            v-model.trim="friend.email"
            @blur="validateField(index, 'email')"
            class="form-control"
            :class="{
              'is-invalid': errors[index]?.email,
              'is-valid': !errors[index]?.email && friend.email
            }"
          />
          <span v-if="errors[index]?.email" class="text-danger mt-1 fs-8">
            {{ $t('email_error_message') }}
          </span>
        </div>

        <div class="col-md mb-3">
          <input
            type="text"
            :placeholder="$t('name')"
            v-model.trim="friend.name"
            @blur="validateField(index, 'name')"
            class="form-control"
            :class="{
              'is-invalid': errors[index]?.name,
              'is-valid': !errors[index]?.name && friend.name
            }"
          />
          <span v-if="errors[index]?.name" class="text-danger mt-1 fs-8">
            {{ $t('field_required_message') }}
          </span>

          <div v-if="index !== 0" class="mt-1 text-end">
            <a href="#" @click.prevent="removeFriend(index)" class="text-primary text-decoration-none">
              {{ $t('booking_confirmation.remove_friend') }}
            </a>
          </div>
        </div>
      </div>

      <button class="btn btn-success rounded-3 mb-3" @click="addFriend">
        {{ $t('booking_confirmation.add_friend') }}
      </button>
      <textarea 
        rows="2"
        v-model="message"
        :placeholder="$t('message')" 
        class="form-control mb-3"
      ></textarea>
      <button class="btn btn-success rounded-3" @click="handleSubmit">
        {{ $t('send') }}
      </button>
    </div>

    <div v-else>
      <p class="fw-bold" :class="isSubmitSuccessful ? 'text-success' : 'text-danger'">
        {{ isSubmitSuccessful ? $t('booking_confirmation.success_message') : $t('error_heading') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Email from '@/components/Icons/Email.vue';
import { useShareConfirmation } from '@/composables/useConfirmation';
import { Friend } from '@/types/confirmation';

const props = defineProps<{
  nonceToken: string; 
  bajaId: string 
}>();

const friends = ref<Friend[]>([{ email: '', name: '' }]);
const message = ref('');
const errors = ref<{ email: boolean; name: boolean }[]>([{ email: false, name: false }]);
const { isSubmitSuccessful, isSubmitFailed, shareConfirmation } = useShareConfirmation();

const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const validateField = (index: number, field: 'email' | 'name') => {
  if (!errors.value[index]) {
    errors.value[index] = { email: false, name: false };
  }

  const friend = friends.value[index];
  if (field === 'email') {
    errors.value[index].email = !validateEmail(friend.email);
  } else if (field === 'name') {
    errors.value[index].name = !friend.name.trim();
  }
};

const validateAll = () => {
  return friends.value.every((_, i) => {
    validateField(i, 'email');
    validateField(i, 'name');
    return !errors.value[i].email && !errors.value[i].name;
  });
};

const addFriend = () => {
  friends.value.push({ email: '', name: '' });
  errors.value.push({ email: false, name: false });
};

const removeFriend = (index: number) => {
  friends.value.splice(index, 1);
  errors.value.splice(index, 1);
};

const handleSubmit = () => {
  if (!validateAll()) return;

  shareConfirmation({
    nonce_token: props.nonceToken,
    baja_id: props.bajaId,
    friends: friends.value,
    message: message.value,
  });
};
</script>

<style scoped>
.btn-success {
  background-color: #00AB3C !important;
  border-color: #00AB3C !important;
}

.btn-success:hover {
  background-color: #008c30 !important;
  border-color: #008c30 !important;
}
</style>
