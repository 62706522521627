<template>
  <div class="row g-3">
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('first_name')"
        v-model.trim="customer.first_name"
        @blur="validateField('first_name')"
        :class="{
          'is-invalid': errors.first_name,
          'is-valid': !errors.first_name && customer.first_name
        }"
      />
      <div v-if="errors.first_name" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('first_name_error_message') }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('last_name')"
        v-model.trim="customer.last_name"
        @blur="validateField('last_name')"
        :class="{
          'is-invalid': errors.last_name,
          'is-valid': !errors.last_name && customer.last_name
        }"
      />
      <div v-if="errors.last_name" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('last_name_error_message') }}</span>
      </div>
    </div>

    <div class="col-12">
      <input
        type="email"
        class="form-control"
        :placeholder="$t('email')"
        v-model.trim="customer.customer_email"
        @blur="validateField('customer_email')"
        :class="{
          'is-invalid': errors.customer_email,
          'is-valid': !errors.customer_email && customer.customer_email
        }"
      />
      <div v-if="errors.customer_email" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('email_error_message') }}</span>
      </div>
    </div>

    <div class="col-12">
      <VueTelInput
        v-bind="phoneOptions"
        @blur="handlePhoneBlur"
        @validate="handlePhoneValidation"
        class="form-control d-flex phone-input"
        :class="{
          'is-invalid': errors.customer_phone,
          'is-valid': !errors.customer_phone && customer.customer_phone
        }"
      ></VueTelInput>
      <div v-if="errors.customer_phone" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('phone_error_message') }}</span>
      </div>
    </div>

    <div class="col-12">
      <input
        type="email"
        class="form-control"
        :placeholder="$t('confirm_email')"
        v-model.trim="customer.email_confirm"
        @blur="handleEmailMatch"
        :class="{
          'is-invalid': errors.email_confirm,
          'is-valid': !errors.email_confirm && customer.email_confirm
        }"
      />
      <div v-if="errors.email_confirm" class="text-danger mt-1">
        <img class="me-1" src="@/assets/images/error.svg" alt="error" />
        <span class="fs-8">{{ $t('email_mismatch_error_message') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import { useCustomerStore } from '@/stores/customerStore';
import { storeToRefs } from 'pinia';

defineProps<{
  handleSubmit: () => void,
  isSubmitDisabled: boolean,
}>();

const customerStore = useCustomerStore();
const { customer, errors } = storeToRefs(customerStore);
const { validateField, handlePhoneValidation, handlePhoneBlur, handleEmailMatch } = customerStore;

const phoneOptions = {
  mode: "international",
  preferredCountries: ["nl", "de", "da", "gb", "us"],
  autoDefaultCountry: true,
  validCharactersOnly: true,
  dropdownOptions: {
    showFlags: true,
    showDialCodeInSelection: true,
    showSearchBox: false,
  },
  inputOptions: {
    placeholder: "1234567890",
  },
};

</script>

<!-- not scoped to apply to phone number dropdown -->
<style>
.vti__dropdown-list {
  font-weight: 100;

  .vti__dropdown-item.last-preferred {
    border-bottom: none !important;
    border-top: 1px solid #cacaca !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>

<style scoped>
.phone-input {
  padding: 0 !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

.terms-label {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
  </style>