import { ref } from 'vue';
import { ShareConfirmationData } from '../types/confirmation';

export function useShareConfirmation() {
  const isSubmitSuccessful = ref(false);
  const isSubmitFailed = ref(false);

  const shareConfirmation = async (formData: ShareConfirmationData) => {
    try {
      const response = await fetch(`${window.location.origin}/wp-json/bajabikes/v1/share-booking-confirmation/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        isSubmitSuccessful.value = true;
      } else {
        isSubmitFailed.value = true;
      }
    } catch (error) {
      console.error("Error:", error);
      isSubmitFailed.value = true;
    }
  };

  return {
    isSubmitSuccessful,
    isSubmitFailed,
    shareConfirmation,
  };
}
