<template>
  <div class="top-bar position-relative">
    <MenuDesktop v-if="!isMobile" :hasMenu="hasMenu" />
    <MenuMobile
      v-else
      :hasMenu="hasMenu"
    />
  </div>
</template>

<script lang="ts" setup>
import { useMobile } from "@/composables/useMobile";

import MenuDesktop from "@/components/MenuDesktop.vue";
import MenuMobile from "@/components/MenuMobile.vue";

const { isMobile } = useMobile();

defineProps({
  hasMenu: {
    type: Boolean,
    default: true,
  },
  isHomePage: {
    type: Boolean,
    default: false,
  },
});

</script>

<style scoped>
  section {
    padding: 8px 0;
  }
</style>
